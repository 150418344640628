<template>
  <div>
    <h4>Examen</h4>
    
    <data-table
      :data-source="sortedExamens"
      :headers="headers"
      table-class="table-striped table-hover"
    >
      <template slot="actions">
        <a
          href="javascript:void(0)"
          class="btn btn-outline-primary btn-icon"
          data-toggle="modal"
          data-target="#add-examen"
        >
          <i class="icofont icofont-plus" />
        </a>
      </template>
    </data-table>
        
    <modal
      title="Ajout d'examen"
      id="add-examen"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="adding"
      >
        <div
          class="alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>
        <div class="form-group">
          <label for="add-type">Type</label>
          <select
            id="add-type"
            name="add-type"
            class="form-control"
            v-model="type"
            v-select="{placeholder: 'Selectionnez le type d\'analyse'}"
          >
            <option value="" />
            <option
              v-for="(t, i) in typesCanHaveCategories"
              :value="t.uid"
              :key="i"
            >
              {{ t.libelle }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="add-categorie">Categorie</label>
          <select
            id="add-categorie"
            name="add-categorie"
            class="form-control"
            v-model="categorie"
            v-select="{placeholder: 'Selectionnez la catgorie d\'analyse'}"
          >
            <option value="" />
            <option
              v-for="(c, i) in filtredCategories"
              :value="c.uid"
              :key="i"
            >
              {{ c.libelle }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="add-t-recherche">Type Recherche</label>
          <select
            id="add-t-recherche"
            name="add-t-recherche"
            class="form-control"
            v-model="typeRecherche"
            v-select="{placeholder: 'Selectionnez la catgorie d\'analyse'}"
          >
            <option value="" />
            <option
              v-for="(c, i) in filtredTypeRecherches"
              :value="c.uid"
              :key="i"
            >
              {{ c.libelle }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="add-libelle">Libelle</label>
          <input
            id="add-libelle"
            name="add-libelle"
            type="text"
            class="form-control"
            v-model="libelle"
          >
        </div>

        <div class="form-group">
          <label for="add-montant">Montant</label>
          <input
            id="add-montant"
            name="add-montant"
            type="text"
            class="form-control"
            v-model="montant"
          >
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="hasFiche"
                  id="add-has-fiche"
                >
                <label
                  class="form-check-label"
                  for="add-has-fiche"
                >
                  Existe fiche d'analyse?
                </label>
              </div>
            </div>
          </div>
        </div>
        <div
          class="row"
          v-if="hasFiche"
        >
          <div class="col-6">
            <div class="form-group">
              <label for="add-type-fiche">Type de fiche</label>
              <select
                class="form-control"
                v-model="typeFiche"
                v-select="{placeholder: 'Selectionnez le type de fiche'}"
                name="add-type-fiche"
                id="add-type-fiche"
              >
                <option value="" />
                <option
                  v-for="(t, i) in typeFiches"
                  :value="t.uid"
                  :key="i"
                >
                  {{ t.libelle }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="add-fiche">Fiche</label>
              <select
                class="form-control"
                v-model="fiche"
                v-select="{placeholder: 'Selectionnez la fiche'}"
                name="add-fiche"
                id="add-fiche"
              >
                <option value="" />
                <option
                  v-for="(f, i) in filtredFicheAnalyses"
                  :value="f.uid"
                  :key="i"
                >
                  {{ f.libelle }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="form-group text-right">
          <button
            type="submit"
            class="btn btn-primary"
          >
            Ajouter
          </button>
        </div>
      </form>
    </modal>

    <modal
      title="Modification d'un examen"
      id="update-examen"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="editting"
      >
        <div
          class="alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>
        <div class="form-group">
          <label for="update-type">Type</label>
          <select
            id="update-type"
            name="update-type"
            class="form-control"
            v-model="type"
            v-select="{placeholder: 'Selectionnez le type d\'analyse'}"
          >
            <option value="" />
            <option
              v-for="(t, i) in typesCanHaveCategories"
              :value="t.uid"
              :key="i"
            >
              {{ t.libelle }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="update-categorie">Categorie</label>
          <select
            id="update-categorie"
            name="update-categorie"
            class="form-control"
            v-model="categorie"
            v-select="{placeholder: 'Selectionnez la catgorie d\'analyse'}"
          >
            <option value="" />
            <option
              v-for="(c, i) in filtredCategories"
              :value="c.uid"
              :key="i"
            >
              {{ c.libelle }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="update-t-recherche">Type Recherche</label>
          <select
            id="update-t-recherche"
            name="update-t-recherche"
            class="form-control"
            v-model="typeRecherche"
            v-select="{placeholder: 'Selectionnez la catgorie d\'analyse'}"
          >
            <option value="" />
            <option
              v-for="(c, i) in filtredTypeRecherches"
              :value="c.uid"
              :key="i"
            >
              {{ c.libelle }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <label for="update-libelle">Libelle</label>
          <input
            id="update-libelle"
            name="update-libelle"
            type="text"
            class="form-control"
            v-model="libelle"
          >
        </div>

        <div class="form-group">
          <label for="update-montant">Montant</label>
          <input
            id="update-montant"
            name="update-montant"
            type="text"
            class="form-control"
            v-model="montant"
          >
        </div>

        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  v-model="hasFiche"
                  id="update-has-fiche"
                >
                <label
                  class="form-check-label"
                  for="update-has-fiche"
                >
                  Existe fiche d'analyse?
                </label>
              </div>
            </div>
          </div>
        </div>
        <div
          class="row"
          v-if="hasFiche"
        >
          <div class="col-6">
            <div class="form-group">
              <label for="update-type-fiche">Type de fiche</label>
              <select
                class="form-control"
                v-model="typeFiche"
                v-select="{placeholder: 'Selectionnez le type de fiche'}"
                name="update-type-fiche"
                id="update-type-fiche"
              >
                <option value="" />
                <option
                  v-for="(t, i) in typeFiches"
                  :value="t.uid"
                  :key="i"
                >
                  {{ t.libelle }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group">
              <label for="update-fiche">Fiche</label>
              <select
                class="form-control"
                v-model="fiche"
                v-select="{placeholder: 'Selectionnez la fiche'}"
                name="update-fiche"
                id="update-fiche"
              >
                <option value="" />
                <option
                  v-for="(f, i) in filtredFicheAnalyses"
                  :value="f.uid"
                  :key="i"
                >
                  {{ f.libelle }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="form-group text-right">
          <button
            type="submit"
            class="btn btn-primary"
          >
            Enregistrer
          </button>
        </div>
      </form>
    </modal>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
import DataTable from '../../../../components/dataTable/local.vue'
import { TEXT_TYPE, COMPONENT_TYPE, BOOLEAN_TYPE } from '../../../../components/dataTable/dataType'
import Modal from '../../../../components/modal.vue'
import { ADD_EXAMEN, UPDATE_EXAMEN } from '../../../../graphql/sanitaire'
const typeAnalyse = () => import('../../../../components/admin/sanitaire/examen/typeAnalyse.vue')
const categorieAnalyse = () => import('../../../../components/admin/sanitaire/examen/categorieAnalyse.vue')
const typeRecherche = () => import('../../../../components/admin/sanitaire/examen/typeRecherche.vue')
const Actions = () => import('../../../../components/admin/sanitaire/examen/actions.vue')
export default {
    components: { DataTable, Modal},
    data(){
        return {
            libelle: null,
            categorie: null,
            typeRecherche: null,
            filtredCategories: [],
            filtredTypeRecherches: [],
            filtredFicheAnalyses: [],
            type: null,
            montant: 0,
            hasFiche: false,
            typeFiche: null,
            fiche: null,
            has_error: false,
            error_msg: null
        }
    },
    watch: {
        type(){
            if(this.type !== null){
                this.filtredCategories = [...this.categories].filter(item => item.typeAnalyse === this.type && item.hasRecherche)
            } else this.filtredCategories =  []
        },
        categorie(){
            if(this.categorie !== null){
                this.filtredTypeRecherches = [...this.typeRecherches].filter(item => item.categorieAnalyse === this.categorie)
            } else this.filtredTypeRecherches = []
        },
        selectedExamen: {
            handler(){
                if(this.selectedExamen !== null) {
                    this.libelle = this.selectedExamen.libelle
                    this.montant = this.selectedExamen.montant
                    this.typeRecherche = this.selectedExamen.typeRecherche
                    let tr = this.typeRecherches.find(item => item.uid === this.typeRecherche)
                    
                    let c = this.categories.find(item => item.uid === tr.categorieAnalyse)
                    let t = this.types.find(item => item.uid === c.typeAnalyse)
                    this.type = t.uid
                    this.categorie = c.uid
                    this.hasFiche = this.selectedExamen.hasFiche
                    this.fiche = this.selectedExamen.fiche
                }
            },
            deep: true
        },
        typeFiche(){
          this.filtredFicheAnalyses = []
          if(this.typeFiche !== null) this.filtredFicheAnalyses = [...this.ficheAnalyses].filter(item => item.categorieAnalyse === this.typeFiche)
        },
    },
    methods: {
        ...mapMutations({ 
            done: 'DONE',
            setSelectedObject: 'SET_SELECTED_OBJECT'
        }),
        initForm(){
            this.setSelectedObject(null)
            this.libelle = null
            this.montant = null
            this.type = null
            this.categorie =  null
            this.typeRecherche = null
            this.has_error = false
            this.error_msg = null
            this.hasFiche = false
            this.fiche = null
        },
        adding(){
            let data = {
                libelle: this.libelle,
                montant : parseInt(this.montant),
                typeRecherche: this.typeRecherche,
                hasFiche: this.hasFiche,
                fiche: this.fiche
            }
            this.$apollo.mutate({
                mutation: ADD_EXAMEN,
                variables: {
                    "examen": {
                        ...data
                    }
                },
                update: (d) => {
                    console.log(d)
                    console.log(`Examen ${this.libelle} add successfully`)
                }
              }).then(() => {
                this.initForm()
                this.done()
            }).catch((error) => {
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
        },
        editting(){
            let data = {
                libelle: this.libelle,
                montant : parseInt(this.montant),
                typeRecherche: this.typeRecherche,
                hasFiche: this.hasFiche,
                fiche: this.fiche
            }
            this.$apollo.mutate({
                mutation: UPDATE_EXAMEN,
                variables: {
                    "examen": {
                        ...data
                    },
                    "uid": this.selectedExamen.uid
                },
                update: (d) => {
                    console.log(d)
                    console.log(`Examen ${this.libelle} on ${this.selectedExamen.uid} updated successfully`)
                }
              }).then(() => {
                this.initForm()
                this.done()
            }).catch((error) => {
                console.error(error)
                this.has_error = true
                this.error_msg = error
            })
        }
    },
    computed: {
        ...mapGetters({
            types: 'sanitaire/typeAnalyses',
            categories: 'sanitaire/categorieAnalyses',
            selectedExamen: 'selectedObject',
            typeRecherches: 'sanitaire/typeRecherches',
            examens: 'sanitaire/examens',
            typeFiches: 'sanitaire/typeFicheAnalyses',
            ficheAnalyses: 'sanitaire/ficheAnalyses'
        }),
        sortedExamens(){
          return [...this.examens].sort((a,b) => a.libelle.localeCompare(b.libelle))
        },
        typesCanHaveCategories(){
            return [...this.types.filter(item => item.hasCategorie)]
        },
        headers(){
            return [
                {label: 'ID', name: 'uid', type: TEXT_TYPE},
                {label: 'Libelle', name: 'libelle', type: TEXT_TYPE},
                {label: 'Montant', name: 'montant', type: TEXT_TYPE},
                {label: 'Type Analyse', name: 'type_analyse', type: COMPONENT_TYPE, component: typeAnalyse},
                {label: 'Categorie Analyse', name: 'categorie_analyse', type: COMPONENT_TYPE, component: categorieAnalyse},
                {label: 'Type Recherche', name: 'typeRecherche', type: COMPONENT_TYPE, component: typeRecherche},
                {label: 'Has Fiche', name: 'hasFiche', type: BOOLEAN_TYPE},
                {label: 'Fiche', name: 'fiche', type: TEXT_TYPE},
                {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions, style: {with: '20%'}}
            ]
        }
    }
}
</script>

<style>

</style>